import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SignInForm, {
} from '../components/SignIn';
// import { SignUpLink } from '../components/SignUp';
// import { PasswordForgetLink } from '../components/PasswordForget';

const SignInPage = () => (
  <Fragment>
  <div className="adjust-signin">
        <div className="white-board-admin">
    <h1>SignIn</h1>
    <SignInForm />
    </div>
    </div>
  </Fragment>
);

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
);
